import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "เควส-หรือ-ภารกิจ"
    }}>{`เควส หรือ ภารกิจ`}</h3>
    <p>{`การทำเควส ผู้เล่นจะต้องหาผลผลิตที่เควสต้องการมาส่ง ไม่ว่าจะเป็นผลผลิตจากพืช หรือ จากสัตว์ โดยเควสจะกำหนดชนิดของผลผลิต และจำนวนที่ต้องการเอาไว้อย่างชัดเจน`}</p>
    <p><img alt="quest" src={require("./public/images/quest-1.png")} /></p>
    <ul>
      <li parentName="ul">{`เควสพื้นฐาน`}</li>
    </ul>
    <p>{`ผู้เล่นที่ซื้อ Standard Farm มา จะสามารถทำเควสพื้นฐานภายในฟาร์มนั้นได้ โดยรับเควสจากไอคอนเควสบอร์ดภายในเกม`}</p>
    <p><img alt="quest" src={require("./public/images/quest-2.png")} /></p>
    <ul>
      <li parentName="ul">{`เควสพิเศษ`}</li>
    </ul>
    <p>{`ผู้เล่นที่ซื้อ Great Farmland มา จะสามารถทำเควสพิเศษภายในฟาร์มนั้นได้ โดยรับเควสจากไอคอนเควสบอร์ดภายในเกม`}</p>
    <p><img alt="quest" src={require("./public/images/quest-3.png")} /></p>
    <ul>
      <li parentName="ul">{`รางวัลเควส`}</li>
    </ul>
    <p>{`รางวัลเควสจะได้ตามผืนฟาร์มที่เล่น โดยเควสจาก Great Farmland จะให้รางวัลมากกว่าเควสจาก Standard Farmland`}</p>
    <ul>
      <li parentName="ul">{`ระยะเวลารับเควส`}</li>
    </ul>
    <p>{`เมื่อทำเควสเสร็จ หรือ เควสล้มเหลวจากการหมดเวลา จะมีการติดคูลดาวน์ก่อนที่จะมีเควสถัดไปปรากฎขึ้นมาให้ทำ ( ระยะเวลาคูลดาวน์จะขึ้นอยู่กับความสามารถสกิลของตัวละครที่ผูกสัญญากับฟาร์ม )`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      